@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

:root {
  --golden: #FFB80C;
  --chocolate: #4B370F;
  --gray: #D7D7D7;
  --dark-gray: #606060;
  --dark: #282828;
  --red: #FF4848;
  --light-green: #409C1F;
  --placeholder: #B9B9B9;

  --blackish-ash: #373737;

  --white: #ffffff;
  --black: #000000;

  --light-blue: #EFF8FF;
  --blue: #0092E6;
}

body {
  margin: 0;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.audiowide-font {
  font-family: 'Audiowide', sans-serif;
}

.red-text {
  color: var(--red);
}

.golden-text {
  color: var(--golden);
}

.pointer {
  cursor: pointer;
}

/* custom-backgrounds */
.dark-background {
  background-color: var(--dark);
}

.gray-background {
  background-color: var(--gray);
}

.dark-gray-background {
  background-color: var(--dark-gray);
}

.placeholder-background {
  background-color: var(--placeholder);
}

.blackish-ash-background {
  background-color: var(--blackish-ash);
}

.golden-background {
  background-color: var(--golden);
}

.light-green-background {
  background-color: var(--light-green);
}

.light-blue-background {
  background-color: var(--light-blue);
}

.white-background {
  background-color: var(--white);
}

.red-background {
  background-color: var(--red);
}

/* ============ golden button =========== */
.golden-button {
  color: var(--black);
  background-color: var(--golden);
  border-color: var(--golden);
  transition: 0.3s;
  white-space: nowrap;
}

.golden-button:hover {
  color: var(--black);
  background-color: var(--golden);
  border-color: var(--golden);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.golden-button:active {
  color: var(--black);
  background-color: var(--golden);
  border-color: var(--golden);
}

.golden-button:disabled {
  color: var(--black);
  background-color: var(--transparent-golden);
  border-color: var(--transparent-golden);
}

/* ============ chocolate button =========== */
.chocolate-button {
  color: var(--white);
  background-color: var(--chocolate);
  border-color: var(--chocolate);
  transition: 0.3s;
  white-space: nowrap;
}

.chocolate-button:hover {
  color: var(--white);
  background-color: var(--chocolate);
  border-color: var(--chocolate);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.chocolate-button:active {
  color: var(--white);
  background-color: var(--chocolate);
  border-color: var(--chocolate);
}

.chocolate-button:disabled {
  color: var(--white);
  background-color: var(--transparent-chocolate);
  border-color: var(--transparent-chocolate);
}

/* ============ white button =========== */
.white-button {
  color: var(--black);
  background-color: var(--white);
  border-color: var(--white);
  transition: 0.3s;
  white-space: nowrap;
}

.white-button:hover {
  color: var(--black);
  background-color: var(--white);
  border-color: var(--white);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.white-button:active {
  color: var(--black);
  background-color: var(--white);
  border-color: var(--white);
}

.white-button:disabled {
  color: var(--black);
  background-color: var(--transparent-white);
  border-color: var(--transparent-white);
}

/* ============ dark button =========== */
.dark-button {
  color: var(--white);
  background-color: var(--dark);
  border-color: var(--dark);
  transition: 0.3s;
  white-space: nowrap;
}

.dark-button:hover {
  color: var(--white);
  background-color: var(--dark);
  border-color: var(--dark);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.dark-button:active {
  color: var(--white);
  background-color: var(--dark);
  border-color: var(--dark);
}

.dark-button:disabled {
  color: var(--white);
  background-color: var(--transparent-white);
  border-color: var(--transparent-white);
}

/* ============ blackish ash button =========== */
.blackish-ash-button {
  color: var(--white);
  background-color: var(--blackish-ash);
  border-color: var(--blackish-ash);
  transition: 0.3s;
  white-space: nowrap;
}

.blackish-ash-button:hover {
  color: var(--white);
  background-color: var(--blackish-ash);
  border-color: var(--blackish-ash);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.blackish-ash-button:active {
  color: var(--white);
  background-color: var(--blackish-ash);
  border-color: var(--blackish-ash);
}

.blackish-ash-button:disabled {
  color: var(--white);
  background-color: var(--blackish-ash);
  border-color: var(--blackish-ash);
}

/* ============ green button =========== */
.green-button {
  color: var(--white);
  background-color: var(--light-green);
  border-color: var(--light-green);
  transition: 0.3s;
  white-space: nowrap;
}

.green-button:hover {
  color: var(--white);
  background-color: var(--light-green);
  border-color: var(--light-green);
  font-weight: bold;
  box-shadow: 2px 0px 8px 0px var(--gray);
}

.green-button:active {
  color: var(--white);
  background-color: var(--light-green);
  border-color: var(--light-green);
}

.green-button:disabled {
  color: var(--white);
  background-color: var(--transparent-white);
  border-color: var(--transparent-white);
}

/* page-titles styles */

.page-title-underline {
  padding-bottom: 5px;
}

.light .page-title-underline {
  border-bottom: 1px solid var(--gray);
}

.dark .page-title-underline {
  border-bottom: 1px solid var(--gray);
}

/* coustom inputs */

input {
  outline: none;
  border: none;
}

/* ============ scrollbar start =========== */
::-webkit-scrollbar {
  height: 0;
  /* width: 0; */
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: none; */
  -webkit-box-shadow: inset 0 0 2px var(--dark);
}

::-webkit-scrollbar-thumb {
  /* -webkit-box-shadow: none; */
  -webkit-box-shadow: inset 20px 10px var(--gray);
}

/* ============ scrollbar end =========== */

.light ::-webkit-calendar-picker-indicator {
  filter: invert(0);
}

.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.light .custom-shadow {
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.dark .custom-shadow {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
}

/* swal styles */

div:where(.swal2-container) button:where(.swal2-close):hover {
  color: var(--golden) !important;
}

@media screen and (min-width: 360px) and (max-width: 420px) {
  div:where(.swal2-container) button:where(.swal2-close) {
    font-size: 2.5em !important;
  }
}